import { DomainModel } from '@domain/domain.model';
import { Location } from '@domain/models/location.model';
import { RelationGroup } from '@domain/models/relation-group.model';

import * as uuid from 'uuid/v4';

export class Client extends DomainModel {
  // Configuration
  public entity = 'client';
  public table = 'clients';
  public schema = 'id, remote_id';
  public sync = true;
  public id: string;

  // Fields
  public name: string;
  public location_id?: number;
  public relation_group_id?: number;
  public description: string;
  public remarks: string;
  public location: Location;
  public relationGroup: RelationGroup;

  public arent_order_number?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    if (this.location_id) {
      this.location = await Location.query.get(this.location_id);
    }
    if (this.relation_group_id) {
      this.relationGroup = await RelationGroup.query.get(this.relation_group_id);
    }
  }

  public getData(): any {
    return {
      id: this.id,
      name: this.name || '',
      relation_group_id: this.relation_group_id,
      location_id: this.location_id,
      description: this.description || '',
      remarks: this.remarks || ''
    };
  }
}
