import { DomainModel } from '@domain/domain.model';
import { Specialty } from '@domain/models/specialty.model';

import * as uuid from 'uuid/v4';

export class ProjectSpecialty extends DomainModel {
  // Configuration
  public entity = 'project-specialty';
  public table = 'project_specialties';
  public schema =
    'id, remote_id, project_id, specialty_id, [specialty_id+project_id]';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public specialty_id: number;
  public applicable: boolean;
  public details: string;
  public hours_estimate: number;
  public start: string;
  public startDate: Date;

  public specialty: Specialty;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    this.startDate = this.start ? new Date(this.start) : null;

    // Set relations
    this.specialty = await Specialty.query.get(this.specialty_id);
  }

  public getData() {
    return {
      id: this.id,
      project_id: this.project_id,
      specialty_id: this.specialty_id,
      applicable: this.applicable,
      details: this.details || '',
      hours_estimate: this.hours_estimate || 0,
      start: this.formatDate(this.startDate)
    };
  }

  public updateDate() {
    this.start = this.formatDate(this.startDate);
  }
}
