import { DomainModel } from '@domain/domain.model';

export class Machine extends DomainModel {

    // Configuration
    public entity = 'machine';
    public table = 'machines';
    public schema = '++id';
    public sync = true;

    // Fields
    public name: string;

    // Constructor
    constructor(attributes) {
        super(attributes);
    }
}
