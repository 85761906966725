import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { AddressType } from '@domain/models/address-type.model';

import * as uuid from 'uuid/v4';

export class Address extends DomainModel {
  // Configuration
  public entity = 'address';
  public table = 'addresses';
  public schema = 'id, remote_id, project_id';
  public sync = true;
  public id: string;

  // Fields
  public address_type_id?: number;
  public index?: number;
  public project_id?: string;
  public type?: string;
  public street?: string;
  public housenumber?: string;
  public housenumber_add?: string;
  public zipcode?: string;
  public city?: string;
  public country?: string;
  public floor?: string;
  public accessible_with_max?: string;
  public distance_to_building?: string;
  public parking_charge?: string;
  public lon?: string;
  public lat?: string;
  public email?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    await AddressType.query.get(this.address_type_id).then(result => {
      this.type = result.name;
    });
  }

  /**
   * Return a display friendly name
   */
  public getDisplayName(): string {
    return `${this.type || ''}: ${this.street || ''} ${this.housenumber || ''}${this.housenumber_add || ''}, 
    ${this.zipcode || ''}, ${this.city || ''}, ${this.country || ''}`;
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      address_type_id: this.address_type_id,
      index: this.index,
      street: this.street || '',
      housenumber: this.housenumber || '',
      housenumber_add: this.housenumber_add || '',
      zipcode: this.zipcode || '',
      city: this.city || '',
      country: this.country || '',
      lon: this.lon || 0,
      lat: this.lat || 0,
      email: this.email || ''
    };
  }
}
