import { Component, OnInit } from '@angular/core';

import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-user-overview',
  templateUrl: 'user-overview.component.html'
})
export class UserOverviewComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(
    private router: Router
  ) {}

  public async ngOnInit() {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'E-mail', name: 'email', filter: { global: true } },
        { title: 'Rol', name: 'roleName', filter: {
          type: 'select',
          column: 'role_id',
          options: [
            { label: 'Alles', value: '' },
            { label: 'Administrator', value: 2 }, // TODO Use role code and associated filters when Blueprint supports it
            { label: 'Gebruiker', value: 3 },
            { label: 'Uitvoerder', value: 4 },
          ],
        } }
      ],
      endpoint: '/user',
      search: true,
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.roleName = row.role.name;
        }
        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/users(popup:admin/user/${row.id})`);
  }

  public onAddClick() {
    this.router.navigateByUrl('/admin/users(popup:admin/user/add)');
  }
}
