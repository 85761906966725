import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@blueprint/auth/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @ViewChild('loginForm') loginForm: FormGroup;

  public user: { email: string, password: string };
  public loginError = false;
  public resetError = false;
  public resetSuccess = false;
  public today: Date;
  public environment: any;

  private loginBusy = false;

  public constructor(private authService: AuthService, private router: Router) {
    this.user = {
      email: '',
      password: ''
    };

    this.today = new Date();
    this.environment = environment;
  }

  public onSubmit() {
    if (this.loginBusy) {
      return;
    }

    this.loginError = false;

    if (this.loginForm.valid) {
      this.loginBusy = true;
      this.authService.login(this.user.email, this.user.password).subscribe(result => {
        if (result) {
          // Navigate to admin part
          this.router.navigate(['/admin']);
        }
        this.loginBusy = false;
      }, err => {
        // Show login error message
        this.loginError = true;
        this.loginBusy = false;
      });
    }
  }

  public resetPassword() {
    if (this.user.email !== '') {
      this.authService.resetPassword(this.user.email).subscribe(result => {
        if (result) {
          this.resetError = false;
          this.resetSuccess = true;
        }
      }, err => {
        // Then what?
      });
    } else {
        // Show login error message
      this.resetError = true;
    }
  }
}
