import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

import { SharedModule } from '@shared/shared.module';

import { DataTableModule, SharedModule as PrimeNGSharedModule, DropdownModule, DialogModule, CheckboxModule, RadioButtonModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';

import { RouterModule } from '@angular/router';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { ProjectService } from '@shared/services/project.service';
import { BlankInvoiceComponent } from './blank-invoice/blank-invoice.component';

@NgModule({
  providers: [
    ProjectService
  ],
  declarations: [
    ProjectOverviewComponent,
    BlankInvoiceComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DataTableModule,
    TableModule,
    PrimeNGSharedModule,
    EmendisBlueprintModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule
  ],
  exports: [
    ProjectOverviewComponent
  ],
})

export class ProjectModule {
}
