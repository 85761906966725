import { DomainModel } from '@domain/domain.model';
import { InventoryItem } from '@domain/models/inventory-item.model';

import * as uuid from 'uuid/v4';

export class Inventory extends DomainModel {
  // Configuration
  public entity = 'inventory';
  public table = 'inventories';
  public schema = 'id, project_id, default_inventory_id';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public default_inventory_id: number;
  public name?: string;
  public building?: string;
  public floor?: string;
  public volume?: number;

  public packing_amount = 0;
  public assembly_amount = 0;

  public items: InventoryItem[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    this.items = await InventoryItem.query
      .where('inventory_id')
      .equals(this.id)
      .toArray();
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      default_inventory_id: this.default_inventory_id,
      name: this.name || '',
      building: this.building || '',
      floor: this.floor || 0,
      packing_amount: this.packing_amount || 0,
      assembly_amount: this.assembly_amount || 0
    };
  }
}
