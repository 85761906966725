import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges
} from '@angular/core';
import { SelectItem } from 'primeng/primeng';

import { MaterialGroup } from '@domain/models/material-group.model';

@Component({
  selector: 'app-material-header',
  templateUrl: 'material-header.component.html',
  styleUrls: ['./material-header.component.scss']
})
export class MaterialHeaderComponent implements OnChanges {
  @Input('materialGroups') materialGroups;

  @Output('materialGroupChange') materialGroupChange = new EventEmitter<MaterialGroup>();

  public materialGroupList: SelectItem[] = [];
  public materialGroup: MaterialGroup;

  public ngOnChanges() {
    this.loadMaterialGroups();
  }

  /**
   * When new inventory is selected
   */
  public onMaterialGroupChange() {
    this.materialGroupChange.emit(this.materialGroup);
  }

  /**
   * Load inventories for dropdown
   */
  private loadMaterialGroups() {
    this.materialGroupList = [];
    if (!this.materialGroups) {
      return;
    }

    this.materialGroups.forEach((materialGroup: MaterialGroup) => {
      this.materialGroupList.push({
        label: materialGroup.name,
        value: materialGroup
      });
    });
  }
}
