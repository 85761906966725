import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Picture } from '@domain/models/picture.model';
import { Router } from '@angular/router';
import { Project } from '@domain/models/project.model';

@Component({
  selector: 'app-picture-item',
  templateUrl: './picture-item.component.html',
  styleUrls: ['./picture-item.component.scss']
})
export class InventoryPictureItemComponent {
  @Input()
  public project: Project;

  @Input()
  public picture: Picture;

  @Output()
  public print: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public delete: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private router: Router
  ) {
  }

  /**
   * On Print click
   * 
   * @param event: any
   * @param pictureId: string
   * 
   * @returns void
   */
  public onPrintClick(event, pictureId: string): void {
    event.stopPropagation();
    this.print.emit(pictureId);
  }

  /**
   * On Delete click
   * 
   * @param event: any
   * @param pictureId: string
   * 
   * @returns void
   */
  public onDeleteClick(event, pictureId: string): void {
    event.stopPropagation();
    this.delete.emit(pictureId);
  }

  /**
   * On Save click
   * 
   * @param event: any
   * 
   * @returns void
   */
  public onSaveClick(event): void {
    event.stopPropagation();
  }

  /**
   * Route to picture detail
   * 
   * @param pictureId: string
   * @returns void
   */
  public onPictureClick(event, pictureId: string): void {
    event.preventDefault();
    this.router.navigateByUrl(`/admin/project/${this.project.id}/pictures(popup:admin/project/pictures/${pictureId})`);
  }
}
