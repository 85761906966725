import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  name: 'Acceptatie',
  applicationName: 'PA Van Rooyen - MBO',
  baseUrl: 'https://mbo.pavanrooyen.inventorycomplete.acceptatie.emendis.nl/api',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: false
  },
  db_name: 'pavanrooyen_mbo_db',
  clientName: 'pavanrooyen-mbo'
};
