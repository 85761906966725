import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDateProperty'
})
export class SortByDatePropertyPipe implements PipeTransform {
  transform(values: any[], dateProperty: string, sortOrder: 'asc' | 'desc'): any {
    if (sortOrder === 'desc') {
      return values.sort((a, b) => new Date(b[dateProperty]).getTime() - new Date(a[dateProperty]).getTime());
    } else {
      return values.sort((a, b) => new Date(a[dateProperty]).getTime() - new Date(b[dateProperty]).getTime());
    }
  }
}
