import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@blueprint/auth/auth.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {

  public currentRoute = 'home';
  public columnClass = 'col-xs-3';

  constructor(private router: Router, private authService: AuthService, public user: UserService) {
  }

  ngOnInit() {
    this.setCurrentRoute(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setCurrentRoute(this.router.url);
      }
    });

    if (this.user.isAdministrator()) {
      this.columnClass = 'col-xs-2 col-xs-2-4';
    }
  }

  ngOnChanges() {
  }

  private setCurrentRoute(url: string) {
    if (url.includes('/admin/projects')) {
      this.currentRoute = 'home';
    } else if (url.includes('/admin/project')) {
      this.currentRoute = 'project';
    } else if (url.includes('/admin/users')) {
      this.currentRoute = 'users';
    } else if (url.includes('/admin/settings')) {
      this.currentRoute = 'settings';
    } else {
      this.currentRoute = 'home';
    }
  }

  public navigate(url) {
    this.setCurrentRoute(url);
    this.router.navigate([url]);
  }

  /**
   * TODO: use blueprint logout method from auth service (couldn't get it to work quick enough :( )
   *
   * @returns {Promise<void>}
   */
  public logout() {
    this.authService.logout().subscribe(result => {
        if (result) {
          // Navigate to admin part
          this.router.navigate(['/login']);
        }
      }, err => {
        // Show login error message
      });
  }
}
