import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as toastr from 'toastr';
import { ApiService } from '@blueprint/services/api.service';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableComponent, OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Router } from '@angular/router';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';
import { SelectItem } from '@node_modules/primeng/primeng';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html',
  styleUrls: ['project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('projectSearchTable')
  projectSearchTable: OnlineTableComponent;
  @ViewChild('projectOpenTable')
  projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  public projectSearchTableOptions: OnlineTableOptions;

  public displayModal: boolean;
  public selectedProjectType: string;
  public specialties: any[];
  public activities: any[];
  public relationGroupList: any[];
  public numberOfSpecialties: number;
  public numberOfActivities: number;

  private subscriptionSynchronisingCompleted: Subscription;

  public constructor(
    private api: ApiService,
    private projectService: ProjectService,
    private synchronisationService: SynchronisationService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private userService: UserService
  ) {
    this.displayModal = false;
    this.selectedProjectType = 'business';

    this.specialties = [];
    this.activities = [];
    this.relationGroupList = [];
    this.numberOfSpecialties = 0;
    this.numberOfActivities = 0;
  }

  public async ngOnInit(): Promise<any> {
    this.api.get('/relation-group/list').subscribe((result) => {
      this.relationGroupList = [{ value: '', label: 'Alles' }, ...result.data];

      this.loadOpenProjectTable();
      this.loadSearchProjectTable();

      this.subscriptionSynchronisingCompleted = this.synchronisationService.SynchronisingCompleted.subscribe(() => {
        this.loadOpenProjectTable();
      });

      // Always synchronise when opening project overview
      this.synchronisationService.synchronise();
    });
  }

  public ngOnDestroy() {
    if (this.subscriptionSynchronisingCompleted) {
      this.subscriptionSynchronisingCompleted.unsubscribe();
    }
  }

  public loadOpenProjectTable() {
    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Relatiegroep', name: 'clientRelationGroupName' },
        { title: 'Referentie', name: 'reference_nr' },
        { title: 'Status', name: 'status' },
        { title: 'Accountbeheerder', name: 'accountmanagerName' }
      ],
      withDelete: true,
      url: '/projects',
      tableName: 'projects',
      search: false,
      rowDataTransformer: rows => {
        for (const row of rows) {
          row.status = Project.getStatusName(row.status);
        }

        return rows;
      }
    });
  }

  public async loadSearchProjectTable() {
    const statusFilterOptions = [
      { label: 'Alles', value: '' },
      { label: 'Nieuw', value: 'new' },
      { label: 'Voorfactuur', value: 'booked' },
      { label: 'Gepland', value: 'pending' },
      { label: 'Lost', value: 'lost' },
      { label: 'Afgerond', value: 'inactive' }
    ];

    const preFillStatus = localStorage.getItem('status-preference-value');
    if (preFillStatus && statusFilterOptions.find((status) => status.value === preFillStatus)) {
      statusFilterOptions.sort((a: SelectItem, b: SelectItem) => a.value === preFillStatus ? -1 : b.value === preFillStatus ? 1 : 0);
    }

    this.projectSearchTableOptions = new OnlineTableOptions({
      columns: [
        {
          title: 'Relatiegroep',
          name: 'clientRelationGroupName',
          filter: {
            type: 'select',
            field: 'clients.relation_group_id',
            options: this.relationGroupList,
          }
        },
        {
          title: 'Referentie',
          name: 'reference_nr',
          filter: {
            global: true,
          }
        },
        {
          title: 'Status',
          name: 'status',
          filter: {
            type: 'select',
            options: statusFilterOptions
          }
        },
        {
          title: 'Accountbeheerder',
          name: 'accountmanagerName',
          filter: {
            global: true,
            field: 'accountmanagers.name',
          }
        }
      ],
      endpoint: '/projects',
      search: true,
      rowDataTransformer: rows => {
        for (const row of rows) {
          const status = statusFilterOptions.find(key => key.value === row.status);
          row.status = status ? status.label : row.status;
          row.clientRelationGroupName = row.client.relation_group.name;
          row.accountmanagerName = row.accountmanager.name;
        }

        return rows;
      },
      withDelete: this.userService.isAdministrator()
    });

    // Set default status filter to all
    if (this.projectSearchTable && this.projectSearchTable.dataTable) {
      this.projectSearchTable.dataTable.filters['status'] = { value: preFillStatus ? preFillStatus : '' };
    }
  }

  public async onOpenProjectTableRowClick(data: any) {
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async onSearchProjectTableRowClick(data: any) {
    // Load project data from server and then navigate to detail view
    this.projectSearchTable.loading = true;
    await this.synchronisationService.loadSingleProjectData(data.id);
    this.projectSearchTable.loading = false;

    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async addNewProject() {
    const project = await this.projectService.newProject();
    this.router.navigateByUrl(`/admin/project/${project.id}/client`);
  }

  public async closeOpenProjects() {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      toastr.warning('Geen projecten geselecteerd');
      return;
    }

    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde projecten synchroniseren en sluiten?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        this.projectOpenTable.loading = true;
        const closeProjectIds: number[] = this.projectOpenTable.selectedRows.map(row => row.id);
        const result: boolean = await this.synchronisationService.syncToBackend(true);
        if (!result) {
          toastr.error('Projecten synchroniseren sluiten mislukt. Controleer uw verbinding.');

          return;
        }

        // Retrieve open projects except selectedrows
        for (const row of this.projectOpenTable.rows) {
          if (closeProjectIds.indexOf(row.id) === -1) {
            await this.synchronisationService.loadSingleProjectData(row.id);
          }
        }

        window.location.reload();
      }
    });
  }

  /**
   * Toggle display boolean for showing the print modal
   *
   * @param event
   */
  public toggleModalDisplay(event: any): void {
    this.displayModal = true;
  }

  /**
   * Print the blank invoice based on project type
   */
  public printBlankInvoice(): void {
    this.displayModal = false;

    const divToPrint = document.getElementById('blank_invoice_pdf');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
      ' <head>' + head.innerHTML + '</head>' +
      ' <body>' +
      '  <img src="/assets/images/logo.png"><br /><br />' +
      '   ' + divToPrint.innerHTML + '' +
      ' </body>' +
      '</html>');

    const elements = popupWin.document.getElementsByTagName('h5');
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  public setSpecialtiesLength(): void {
    if (!(Number(this.numberOfSpecialties) && Number(this.numberOfSpecialties < 15))) {
      return;
    }

    const length = Number(this.numberOfSpecialties);

    this.specialties = [];
    for (let i = 0; i < length; i++) {
      this.specialties.push(i);
    }
  }

  public setActivitiesLength(): void {
    if (!(Number(this.numberOfActivities) && Number(this.numberOfActivities < 15))) {
      return;
    }

    const length = Number(this.numberOfActivities);

    this.activities = [];
    for (let i = 0; i < length; i++) {
      this.activities.push(i);
    }
  }

  public setType(projectType: string): void {
    this.selectedProjectType = projectType;
  }

  private transformRows(rows: Array<any>) {
    // Determine open projects
    const openProjects = this.projectOpenTable.rows;
    const openProjectGuids = openProjects ? openProjects.map(project => project.id) : [];
    const deleteRows = [];

    for (const row of rows) {
      // Remove rows which are already opened
      if (openProjectGuids.includes(row.id)) {
        deleteRows.push(row);
      }

      row.client = row.client ? row.client.name : '';
      row.accountmanager = row.accountmanager ? row.accountmanager.name : '';
      row.status = Project.getStatusName(row.status);
    }

    for (const row of deleteRows) {
      rows.splice(rows.indexOf(row), 1);
    }

    return rows;
  }
}
