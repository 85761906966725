import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { WorkAssignment } from '@domain/models/work-assignment.model';

@Component({
  selector: 'app-inventory-work-assignment-list',
  templateUrl: 'work-assignment-list.component.html'
})
export class InventoryWorkAssignmentListComponent implements OnInit, OnDestroy {
  private project: Project;
  public tableOptions: OfflineTableOptions;
  @ViewChild(OfflineTableComponent)
  table: OfflineTableComponent;

  private subscriptionProjectLoaded: Subscription;

  get orderedActivities() {
    return this.project.activities.sort((a, b) => {
      return <any>new Date(a.startDate) - <any>new Date(b.startDate);
    });
  }

  constructor(private router: Router, private confirmationService: ConfirmationService, private projectService: ProjectService) {}

  public ngOnInit() {
    this.project = this.projectService.getProject();
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(project => {
      this.project = project;
      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
    });
    this.setTableOptions();
  }

  public ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public onRowClick(data: any) {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + data.id);
  }

  public async onAddClick() {
    // Create new work assignment, cope work description and open detail
    const workAssignment = new WorkAssignment({
      project_id: this.project.id,
      description_own: this.project.own_description_activities,
      description_client: this.project.client_description_activities
    });
    workAssignment.setDefaults();
    await this.projectService.saveWorkAssignment(workAssignment);
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + workAssignment.id);
  }

  public onDeleteClick() {
    if (!this.table.selectedRows || this.table.selectedRows.length === 0) {
      return;
    }
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde werkopdrachten verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.table.selectedRows.forEach(async row => {
          await this.projectService.deleteWorkAssignment(row);
        });
        this.table.onChangeTable();
      }
    });
  }

  private setTableOptions() {
    this.tableOptions = new OfflineTableOptions({
      paging: true,
      search: false,
      columns: [
        { title: 'Datum', name: 'date' },
        { title: 'Afhaaladres', name: 'pickupAddressName' },
        { title: 'Afleveradres', name: 'deliverAddressName' }
      ],
      noResultsMessage: 'Er zijn nog geen werkopdrachten aangemaakt',
      withDelete: true,
      url: '/work_assignments',
      tableName: 'work_assignments',
      filtering: {
        hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }],
      },
      rowDataTransformer: rows => {
        for (const row of rows) {
          row.pickupAddressName = row.address_work_assignments
            .filter(item => item.type === 'pickup')
            .map(item => (item.address ? item.address.getDisplayName() : ''))
            .join('<br/>');
          row.deliverAddressName = row.address_work_assignments
            .filter(item => item.type === 'delivery')
            .map(item => (item.address ? item.address.getDisplayName() : ''))
            .join('<br/>');
          row.date = row.displayDate(row.assignmentDate);
        }

        return rows.sort( (rowA, rowB) => {
          /** Sort the rows on date order, newest first */
          return rowA.assignmentDate - rowB.assignmentDate;
        });
      }
    });
  }
}
