import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Address } from '@domain/models/address.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { Subject } from '@node_modules/rxjs';

import { environment } from '@environments/environment';
import { SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-inventory-address-detail',
  templateUrl: 'address-detail.component.html'
})
export class InventoryAddressDetailComponent implements OnInit, OnDestroy {
  public form;
  public errors: any = {};
  public result;
  public showErrors = false;
  public addressTypes: any = [];
  public address = new Address({});
  public project = new Project({});
  public routeAddressId;
  public ownAddresses: any[];
  public allAddresses: any[];
  public projectAddresses: Address[];
  public globalAddressFilters: any[];
  public disabled = false;
  public isInvoiceAddressType = false;
  public invoiceAddressTypeId: number;
  public environment: object;
  public indexes: SelectItem[];

  private subscriptionAddressLoaded: Subscription;
  private destroy$ = new Subject<boolean>();

  public mode = {
    isAdd: true
  };

  public constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder) {
    this.makeOwnAddresses();
    this.invoiceAddressTypeId = null;
    this.environment = environment;
  }

  public ngOnInit(): any {
    this.initForm();
    this.loadLists();

    this.project = this.projectService.getProject();
    this.disabled = this.project.status === 'booked';

    // Get id of address to edit by route params
    this.route.params.subscribe(params => {
      this.routeAddressId = params['id'];

      if (this.routeAddressId) {
        this.mode.isAdd = false;
        this.projectService.getAddress(this.routeAddressId);
        this.subscriptionAddressLoaded = this.projectService.addressLoaded.subscribe((address) => {
          this.address = address;
          this.updateForm();
        });
      }

      this.projectService.projectLoaded.subscribe((project) => {
        this.project = project;
        this.disabled = this.project.status === 'booked';
        this.updateForm();
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public selectOwnAddress(event: any): void {
    const selected = this.ownAddresses.find((address) => address.value === event.value);
    this.updateFormToOwnAddress(selected);
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.address.id),
      project_id: this.formBuilder.control(this.project.id || null),
      address_type_id: this.formBuilder.control({ value: this.address.address_type_id, disabled: this.disabled }, Validators.required),
      globalFilter: this.formBuilder.control({ value: this.globalAddressFilters, disabled: this.disabled }),
      ownAddresses: this.formBuilder.control({ value: this.ownAddresses, disabled: this.disabled }),
      street: this.formBuilder.control({ value: this.address.street, disabled: this.disabled }, Validators.required),
      housenumber: this.formBuilder.control({ value: this.address.housenumber, disabled: this.disabled }, Validators.required),
      housenumber_add: this.formBuilder.control({ value: this.address.housenumber_add, disabled: this.disabled }),
      zipcode: this.formBuilder.control({ value: this.address.zipcode, disabled: this.disabled }, Validators.required),
      city: this.formBuilder.control({ value: this.address.city, disabled: this.disabled }, Validators.required),
      country: this.formBuilder.control({ value: this.address.country || 'Nederland', disabled: this.disabled }, Validators.required),
      type: this.formBuilder.control({ value: this.address.address_type_id, disabled: this.disabled }),
      floor: this.formBuilder.control(this.address.floor),
      accessible_with_max: this.formBuilder.control(this.address.accessible_with_max),
      distance_to_building: this.formBuilder.control(this.address.distance_to_building),
      parking_charge: this.formBuilder.control(this.address.parking_charge),
      lat: this.formBuilder.control(this.address.lat),
      lon: this.formBuilder.control(this.address.lon),
      email: this.formBuilder.control(this.address.email, Validators.email),
    });

    this.isInvoiceAddressType = this.address.address_type_id === this.invoiceAddressTypeId;
    this.updateFormValidation();

    this.form.controls.address_type_id
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isInvoiceAddressType = value === this.invoiceAddressTypeId;
        this.updateFormValidation();
      });
  }

  public async onAddressChange() {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;

    if (!zipcode || !housenumber) {
      return;
    }

    if (street || city) {
      return;
    }

    const result = await this.api
      .post('/address/search', {
        zipcode: this.form.value.zipcode,
        housenumber: this.form.value.housenumber
      })
      .toPromise();

    if (!result) {
      return;
    }

    // Update street and city values
    this.form.patchValue({
      street: result.street,
      city: result.city
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.address = { ...this.form.value, project_id: this.project.id };

      if (this.mode.isAdd) {
        this.address.index = this.project.addresses ? this.project.addresses.length + 1 : 1;
      }

      this.projectService.saveAddress(this.address);
      this.projectService.setProjectUpdated();
      this.projectService.saveProject();
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address');
  }

  /**
   * Filter addresses on global property
   *
   * @param event: any
   * @returns void
   */
  public filterGlobalAddress(event: any): void {
    if (event.value === null) {
      this.ownAddresses = this.allAddresses.slice();

      return;
    }

    this.ownAddresses = this.allAddresses.slice().filter((address) => address.globalId === event.value);
  }

  private makeOwnAddresses(): void {
    this.ownAddresses = [
      { label: 'Geen', value: 1, street: '', housenumber: '', housenumber_add: '', zipcode: '', city: '' },
      { globalId: 1, street: 'Johan Enschedeweg', housenumber: '5', housenumber_add: '-7', zipcode: '1422 DR', city: 'Uithoorn', value: 2, label: 'Johan Enschedeweg 5, Uithoorn' },
      { globalId: 1, street: 'Industrieweg', housenumber: '52', housenumber_add: '', zipcode: '3641 RM', city: 'Mijdrecht', value: 3, label: 'Industrieweg 52, Mijdrecht' },
    ];

    /** If env == local machine, run --configuration=local for these changes to take effect */
    const additionalAddresses = [
      { street: 'Columbuslaan', housenumber: '540', housenumber_add: '', zipcode: '3526 EP', city: 'Utrecht', value: 4, label: 'Columbuslaan 540, Utrecht' },
      { street: 'Australielaan', housenumber: '23', housenumber_add: '', zipcode: '3526 AB', city: 'Utrecht', value: 5, label: 'Australielaan 23, Utrecht' },
      { street: 'Australielaan', housenumber: '25', housenumber_add: '', zipcode: '3526 AB', city: 'Utrecht', value: 6, label: 'Australielaan 25, Utrecht' },
      { street: 'Australielaan', housenumber: '5', housenumber_add: '', zipcode: '3526 AB', city: 'Utrecht', value: 7, label: 'Australielaan 5, Utrecht' },
      { street: 'Bontekoelaan', housenumber: '1', housenumber_add: '', zipcode: '3526 RA', city: 'Utrecht', value: 8, label: 'Bontekoelaan 1, Utrecht' },
      { street: 'Niels Bohrweg', housenumber: '121', housenumber_add: '', zipcode: '3542 CA', city: 'Utrecht', value: 9, label: 'Niels Bohrweg 121, Utrecht' },
      { street: 'Stationsstraat', housenumber: '2', housenumber_add: '', zipcode: '1211 EM', city: 'Hilversum', value: 10, label: 'Stationsstraat 2, Hilversum' },
      { street: 'Grebbeberglaan', housenumber: '13', housenumber_add: '', zipcode: '3527 VX', city: 'Utrecht', value: 11, label: 'Grebbeberglaan 13, Utrecht' },
      { street: 'Daltonlaan', housenumber: '300', housenumber_add: '', zipcode: '3584 BK', city: 'Utrecht', value: 12, label: 'Daltonlaan 300, Utrecht' },
      { street: 'Daltonlaan', housenumber: '200', housenumber_add: '', zipcode: '3584 BJ', city: 'Utrecht', value: 13, label: 'Daltonlaan 200, Utrecht' },
      { street: 'Daltonlaan', housenumber: '500', housenumber_add: '', zipcode: '3584 BK', city: 'Utrecht', value: 14, label: 'Daltonlaan 500, Utrecht' },
    ];

    this.ownAddresses = [...this.ownAddresses, ...additionalAddresses];

    this.allAddresses = this.ownAddresses.slice();
  }

  private updateFormToOwnAddress(selected: any): void {
    this.form.patchValue(selected);
  }

  private async loadLists() {
    this.result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');
    this.result.forEach((item) => {

      // @ToDo Refactor to be less ugly, this is nessecary because -facturatieadres- in DB is ID #3, and in frontend it's #5
      if (item.name === 'Facturatie adres' || item.name === 'Facturatieadres') {
        this.invoiceAddressTypeId = item.id;
      }

      this.addressTypes.push({ label: item.name, value: item.id });
    }, this);
  }

  private updateFormValidation(): void {
    if (this.isInvoiceAddressType) {
      this.form.get('street').clearValidators();
      this.form.get('street').updateValueAndValidity();

      this.form.get('zipcode').clearValidators();
      this.form.get('zipcode').updateValueAndValidity();

      this.form.get('housenumber').clearValidators();
      this.form.get('housenumber').updateValueAndValidity();

      this.form.get('country').clearValidators();
      this.form.get('country').updateValueAndValidity();

      this.form.get('city').clearValidators();
      this.form.get('city').updateValueAndValidity();

      this.form.get('email').setValidators([Validators.email, Validators.required]);
      this.form.get('email').updateValueAndValidity();
    } else {
      this.form.get('street').setValidators(Validators.required);
      this.form.get('street').updateValueAndValidity();

      this.form.get('zipcode').setValidators(Validators.required);
      this.form.get('zipcode').updateValueAndValidity();

      this.form.get('housenumber').setValidators(Validators.required);
      this.form.get('housenumber').updateValueAndValidity();

      this.form.get('country').setValidators(Validators.required);
      this.form.get('country').updateValueAndValidity();

      this.form.get('city').setValidators(Validators.required);
      this.form.get('city').updateValueAndValidity();

      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
    }
  }

  /**
   * Update form information
   */
  private updateForm() {
    this.form.reset({
      id: this.address.id,
      project_id: this.project.id || null,
      address_type_id: { value: this.address.address_type_id, disabled: this.disabled },
      ownAddresses: { value: this.ownAddresses, disabled: this.disabled },
      street: { value: this.address.street, disabled: this.disabled },
      housenumber: { value: this.address.housenumber, disabled: this.disabled },
      housenumber_add: { value: this.address.housenumber_add, disabled: this.disabled },
      zipcode: { value: this.address.zipcode, disabled: this.disabled },
      city: { value: this.address.city, disabled: this.disabled },
      country: { value: this.address.country || 'Nederland', disabled: this.disabled },
      type: { value: this.address.address_type_id, disabled: this.disabled },
      floor: this.address.floor,
      accessible_with_max: this.address.accessible_with_max,
      distance_to_building: this.address.distance_to_building,
      parking_charge: this.address.parking_charge,
      lat: this.address.lat,
      lon: this.address.lon,
      email: this.address.email,
    });
  }
}
