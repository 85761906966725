import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { ApiService } from '@blueprint/services/api.service';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventory-address-list',
  templateUrl: 'address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class InventoryAddressListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;
  public project = new Project({});
  public addresses: Address[];

  private invoiceFacturationId: number;
  private addAddress: Boolean;
  private subscriptionProjectLoaded: Subscription;
  private subscriptionAddressAdded: Subscription;

  public constructor(private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private projectService: ProjectService) {
    this.getInvoiceFacturationId();
    this.invoiceFacturationId = null;
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.setTableOptions();
    this.getAddresses();

    // In case project was loaded
    this.projectService.projectLoaded.subscribe(project => {
      this.project = project;
      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
      this.getAddresses();
    });

    // In case address was added (from popup)
    this.projectService.addressAdded.subscribe(() => {
      this.table.onChangeTable();
      this.getAddresses();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
    if (this.subscriptionAddressAdded) {
      this.subscriptionAddressAdded.unsubscribe();
    }
  }

  public cloneAddress(row: Address): void {
    delete row.id;
    this.projectService.saveAddress(row);
  }

  public onRowClick(data: any): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/' + data.id + ')');
  }

  public onActionClick(data: any): void {
    this[data.action](data.row);
  }

  public onAddClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/add)');
  }

  public openSortModal(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address-sort)');
  }

  public onDeleteClick(): void {
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde adressen verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.table.selectedRows.forEach((row) => {
          this.projectService.deleteAddress(row.id);
        });
        this.table.onChangeTable();
      }
    });
  }

  private async getAddresses(): Promise<void> {
    this.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
  }

  private async getInvoiceFacturationId() {
    const result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');

    for (const item of result) {
      // @todo Refactor to be less ugly, this is nessecary because -facturatieadres- in DB is ID #3, and in frontend it's #5
      if (item.name === 'Facturatie adres' || item.name === 'Facturatieadres') {
        this.invoiceFacturationId = item.id;
      }
    }
  }

  private setTableOptions(): void {
    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      columns: [
        { title: 'Volgorde', name: 'index' },
        { title: 'Type', name: 'type' },
        { title: 'Adres', name: 'address' }
      ],
      actions: [
        { title: 'Adres dupliceren', action: 'cloneAddress', icon: 'fa-clone' }
      ],
      noResultsMessage: 'Er zijn nog geen adressen aangemaakt',
      withDelete: true,
      url: '/address',
      tableName: 'addresses',
      filtering: { hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }] },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          if (row.address_type_id !== this.invoiceFacturationId) {
            row.address = `${row.street} ${row.housenumber}${row.housenumber_add || ''}, ${row.zipcode} ${row.city}, ${row.country}`;
          } else {
            row.address = row.email;
          }

          if (!row.index) {
            row.index = 'n.v.t.';
          }
        }

        return rows.sort((a, b) => a.index > b.index ? 1 : -1 );
      },
    });
  }
}
