import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Picture } from '@domain/models/picture.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class InventoryPicturesComponent implements OnInit, OnDestroy {
  public project: Project;
  public pictures: Picture[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.project = this.projectService.getProject();
    this.pictures = this.project.pictures.sort((a, b) => a.name < b.name ? 1 : -1);

    this.projectService.projectLoaded
      .pipe(takeUntil(this.destroy$))
      .subscribe((project: Project) => {
        this.project = project;
        this.pictures = project.pictures.sort((a, b) => a.name < b.name ? 1 : -1);;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Prints the picture
   * 
   * @param pictureId: string
   * @returns Promise<void>
   */
  public async printPicture(pictureId: string): Promise<void> {
    const picture = this.pictures.find((pic) => pic.id === pictureId);
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
      '<head>' + head.innerHTML + '</head>' +
      '<body>' +
      '<h5>Project</h5>' + this.project.reference_nr +
      '<h5>Klant</h5>' + this.project.client.name +
      '<h5>Datum</h5>' + picture.timestamp +
      '<h5>Beschrijving</h5>' + picture.description + '<br /><br />' +
      '<img src="' + picture.data + '"><br /><br />' +
      '</body>' +
      '</html>');

    popupWin.print();
    popupWin.document.close();
  }

  /**
   * Gives confirm toast
   * 
   * @param pictureId: string
   * @returns void
   */
  public deletePictureWithConfirm(pictureId: string): void {
    this.confirmationService.confirm({
      message: 'Weet u zeker dat u deze foto wilt verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.delete(pictureId)
      }
    });
  }

  /**
   * Deletes the picture
   * 
   * @param pictureId: string
   * @returns Promise<void>
   */
  private async delete(pictureId: string): Promise<void> {
    await this.projectService.deletePicture(pictureId);
    this.projectService.setProjectUpdated();
    this.projectService.saveProject();
  }

  /**
   * Add another image
   * 
   * @returns void
   */
  public addImage(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/pictures(popup:admin/project/pictures/add)');
  }
}
