import { Component } from '@angular/core';
import { environment  } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public version: string;
  public environment: any;

  constructor() {
    this.version = environment.version;
    this.environment = environment;
  }
}
