import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { InventoryProjectComponent } from '@features/inventory/project/project-detail.component';
import { Injectable } from '@angular/core';


@Injectable()
export class ProjectGuard implements CanDeactivate<InventoryProjectComponent> {
  private home = '/admin/projects';

  canDeactivate(
    component: InventoryProjectComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    if (component.form.invalid && nextState.url !== this.home) {
      component.showErrors = true;
      return false;
    }
    return true;
  }
}
