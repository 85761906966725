import { Injectable } from '@angular/core';
import { environment } from "@root/src/environments/environment";

@Injectable()
export class EnvironmentService {

    constructor() {}

    /**
     * @returns {string}
     */
    public getEnvironmentClientName(): string
    {
        return environment.clientName
    }
}
