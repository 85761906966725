import { DomainModel } from '@domain/domain.model';
import { Address } from '@domain/models/address.model';

import * as uuid from 'uuid/v4';

export class WorkAssignmentAddress extends DomainModel {
  // Configuration
  public entity = 'address-work-assignment';
  public table = 'address_work_assignments';
  public schema = 'id, work_assignment_id';
  public sync = true;
  public id: string;

  // Fields
  public work_assignment_id: string;
  public address_id: string;
  public _new: boolean;
  public type: string;

  public address = new Address({});

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    if (this.address_id) {
      this.address = await Address.query.get(this.address_id);
      if (this.address) {
        await this.address.init();
      }
    }
  }

  public getData() {
    return {
      id: this.id,
      work_assignment_id: this.work_assignment_id,
      address_id: this.address_id,
      _new: this._new,
      type: this.type
    };
  }

  /**
   * Set default values
   */
  public setDefaults() {
    this._new = true;
  }
}
