import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { QuotationSignatureComponent } from '../quotation/signature/quotation-signature.component';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { EnvironmentService } from '@shared/services/environment.service';
import { Machine } from '@domain/models/machine.model';
import { Signature } from '@domain/models/signature.model';
import { SignatureService } from '@shared/services/signature.service';

@Component({
  selector: 'app-inventory-work-assignment-detail',
  templateUrl: 'work-assignment-detail.component.html',
  styleUrls: ['./work-assignment-detail.component.scss']
})
export class InventoryWorkAssignmentDetailComponent implements OnInit, OnDestroy {
  public project: Project;
  public workAssignment: WorkAssignment;
  public addressOptionsPickup: Array<{ label: string; value: string }> = [];
  public addressOptionsDelivery: Array<{ label: string; value: string }> = [];
  public times = [];

  public localeNL: CalendarLocale = new CalendarLocale();
  public signatures: Signature[] = [];

  private addresses: Address[];

  @ViewChild('clientSignature')
  clientSignature: QuotationSignatureComponent;
  @ViewChild('teamleaderSignature')
  teamleaderSignature: QuotationSignatureComponent;

  public machines: any[] = [];
  private subscriptionProjectLoaded: Subscription;

  constructor(
    public environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private syncService: SynchronisationService,
    private projectService: ProjectService,
    private dataService: DataService,
    private signatureService: SignatureService
  ) {
    this.project = this.projectService.getProject();
    this.addresses = this.project.addresses;
    this.orderAddresses();
    this.workAssignment = new WorkAssignment({});

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
      this.addresses = this.project.addresses;
      this.orderAddresses();
      this.initialize();
    });

    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }

    this.initialize();
  }

  public ngOnInit() {
    // Get id of address to edit by route params
    this.route.params.subscribe(async params => {
      const result = await this.projectService.getWorkAssignment(params['id']);
      if (result) {
        this.workAssignment = result;
      }

      this.signatures = await Signature.query.where('work_assignment_id').equals(params['id']).toArray();
    });

    this.setMachines();
  }

  public ngOnDestroy() {
    this.signatureService.addSignatures(this.signatures).subscribe();

    // Cleanup address items without selected address
    this.workAssignment.address_work_assignments = this.workAssignment.address_work_assignments.filter((value: WorkAssignmentAddress) => {
      return value.address_id !== null;
    });

    this.projectService.saveWorkAssignment(this.workAssignment);
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public onAddPersonClick() {
    this.addWorkAssignmentItem('person');
  }

  public onAddCarClick() {
    this.addWorkAssignmentItem('car');
  }

  public onAddAddressClick(type: string): void {
    const newWorkAssignmentAddress = new WorkAssignmentAddress({
      work_assignment_id: this.workAssignment.id, type: type, _new: true, address_id: null
    });
    newWorkAssignmentAddress.setDefaults();
    this.workAssignment.address_work_assignments.push(newWorkAssignmentAddress);
  }

  public onDeleteItemClick(item: WorkAssignmentItem) {
    this.removeWorkAssignmentItem(item);
  }

  public showClientSignatureForm() {
    this.clientSignature.showForm();
  }

  public showTeamleaderSignatureForm() {
    this.teamleaderSignature.showForm();
  }

  public showSignatureForm(reference: QuotationSignatureComponent): void {
    reference.showForm();
  }

  public removeSignature(id: string): void {
    this.signatureService.removeSignature(id).subscribe(() => {
      this.signatures = this.signatures.filter((signature) => {
        return signature.id !== id;
      });
    });
  }

  public onDeleteAddressClick(address: any): void {
    const index = this.workAssignment.address_work_assignments.indexOf(address);
    if (index === -1) {
      return;
    }

    this.workAssignment.address_work_assignments.splice(index, 1);
    this.projectService.deleteWorkAssignmentAddress(address);
  }

  /**
   * Print the work-assignment overview
   */
  public printOverview(): void {
    const divToPrint = document.getElementById('work-assignment');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1100,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
      ' <head>' + head.innerHTML + '</head>' +
      ' <body>' +
      '  <img src="/assets/images/logo.png"><br /><br />' +
      '   ' + divToPrint.innerHTML + '' +
      ' </body>' +
      '</html>');

    // Make address dropdowns full width, as the button is removed in printing
    const viewChildDropdownElementsAddress = popupWin.document.getElementsByClassName('dropdowns');
    for (let i = 0; i < viewChildDropdownElementsAddress.length; i++) {
      viewChildDropdownElementsAddress[i].classList.remove('col-xs-10');
      viewChildDropdownElementsAddress[i].classList.add('col-xs-12');
    }

    // Timeout to make sure the window is loaded
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  /**
   * Returns the address in one complete string
   *
   * @param address: Address
   * @return string
   */
  public getDisplayAddress(address: Address): string {
    const addressParts = [
      `${address.street || ''} ${address.housenumber || ''}${address.housenumber_add || ''}`.trim(),
      `${address.zipcode || ''} ${address.city || ''}`.trim(),
      `${address.country}`.trim()
    ];

    return addressParts.reduce((prev, current) => (prev && current ? prev + ', ' : prev) + current);
  }

  public hasMachines(): boolean {
    return this.machines.length > 0;
  }

  public addSignature(): void {
    this.signatures.push(new Signature({ work_assignment_id: this.workAssignment.id }));
  }

  private async setMachines(): Promise<void> {
    await Machine.query.each(machine => this.machines.push({ label: machine.name, value: machine.name }));
  }

  private addWorkAssignmentItem(type: string) {
    const item = new WorkAssignmentItem({ work_assignment_id: this.workAssignment.id, type: type });
    item.setDefaults();
    this.workAssignment.items.push(item);
  }

  private removeWorkAssignmentItem(item: WorkAssignmentItem) {
    const index = this.workAssignment.items.indexOf(item);
    if (index === -1) {
      return;
    }
    this.workAssignment.items.splice(index, 1);
    this.projectService.deleteWorkAssignmentItem(item);
  }

  private orderAddresses(): void {
    this.addresses.sort((a: Address, b: Address) => {
      if (a.index === null) {
        return 0;
      } else if (b.index === null) {
        return 1;
      }

      return a.index.toString().localeCompare(b.index.toString());
    });
  }

  private initialize() {
    // Add address options
    this.addressOptionsPickup = [];
    this.addressOptionsDelivery = [];
    for (const address of this.addresses) {
      // TODO: filter on type 'pickup' and 'delivery'; not relevant right now
      this.addressOptionsPickup.push({ label: address.getDisplayName(), value: address.id });
      this.addressOptionsDelivery.push({ label: address.getDisplayName(), value: address.id });
    }
  }
}
