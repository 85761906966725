import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import { Quotation } from '@domain/models/quotation.model';
import { Project } from '@domain/models/project.model';
import { Contact } from '@domain/models/contact.model';
import { Address } from '@domain/models/address.model';
import { Client } from '@domain/models/client.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { QuotationSignatureComponent } from './signature/quotation-signature.component';
import { Subscription } from 'rxjs';
import * as toastr from 'toastr';
import { ActivatedRoute } from '@angular/router';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-inventory-quotation',
  templateUrl: 'quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class InventoryQuotationComponent implements OnInit, OnDestroy {
  public project: Project;
  public client: Client;
  public quotation: Quotation;
  public pickupAddress: Address;
  public deliverAddress: Address;
  public disabled = false;
  public project_id: string;
  public workAssignments: WorkAssignment[];
  public workAssignmentAddresses: any;
  public calculatingBasePrice: boolean;
  public environment: object;

  private contact: Contact;
  private addresses: Address[];
  private mainAddress: Address;
  private quotationSent: boolean;

  public localeNL: CalendarLocale = new CalendarLocale();

  @ViewChild('clientSignature') clientSignature: QuotationSignatureComponent;
  @ViewChild('valuatorSignature') valuatorSignature: QuotationSignatureComponent;

  private subscriptionProjectLoaded: Subscription;

  constructor(private syncService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private api: ApiService,
              private route: ActivatedRoute
  ) {
    this.calculatingBasePrice = false;
    this.quotation = new Quotation({});
    this.contact = new Contact({});
    this.client = new Client({});
    this.project = new Project({});
    this.environment = environment;
    this.addresses = [];
    this.quotationSent = false;

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
      this.disabled = this.project.status === 'booked';

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      // Make sure the related properties are present for activities, before sorting them in the view
      if (this.project.activities && this.project.activities.length > 0) {
        for (const activity of this.project.activities) {
          activity.init();
        }
      }

      this.addresses = this.project.addresses;

      // Determine main address and pickup/deliver address
      for (const address of this.addresses) {
        switch (address.type) {
          case 'Hoofd adres':
            this.mainAddress = address;
            break;
          case 'Hoofdadres':
            this.mainAddress = address;
            break;
          case 'Aflever adres':
            this.deliverAddress = address;
            break;
          case 'Afleveradres':
            this.deliverAddress = address;
            break;
          case 'Afhaal adres':
            this.pickupAddress = address;
            break;
          case 'Afhaaladres':
            this.pickupAddress = address;
            break;
          default:
            break;
        }
      }

      // Get first quotation
      if (this.project.quotations.length > 0) {
        this.quotation = this.project.quotations[0];
        // Update storage value total to make sure it's filled
        this.updateTotalStorageValue(this.quotation.storage_value_price);
      } else {
        this.quotation = new Quotation({ project_id: this.project.id, estimated_distance_km: 1 });
      }

      this.fillInvoiceContact();
      this.retrieveActivityAddresses();
      this.initForm();
      this.projectService.setCurrentClient(this.project.client);
    });

    this.fillInvoiceContact();
    this.initForm();
  }

  public getProjectStatus(): string {
    return Project.getStatusName(this.project.status);
  }

  public ngOnInit(): void {
    // Reload project
    this.project = this.projectService.getProject();

    if (this.project.id) {
      this.projectService.loadProject(this.project.id);
    }

    this.route.params.subscribe(async params => {
      const result = await this.projectService.getAllWorkAssignments(params['project']);
      if (result) {
        this.workAssignments = result;
        this.initWorkAssignmentAddresses();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.quotationSent) {
      this.quotation.status = 'already_queued';
    }

    this.saveQuotation();
  }

  public async updateBasePrice(): Promise<void> {
    this.quotation.calculated_volume = this.projectService.calculateVolume();
    this.quotation.distance = this.quotation.distance_km * 1000;

    const volume = this.quotation.total_volume > 0 ? this.quotation.total_volume : this.quotation.calculated_volume;
    let distance = this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km : this.quotation.distance / 1000;

    if (!distance || distance === 0) {
      distance = 1;
    }

    if (distance > 0 && volume > 0) {
      this.calculatingBasePrice = true;

      // Calculated base price, based on distance and total volume from bulk price list
      await this.dataService.findBulkPrice(distance, volume)
        .then((item) => {
          if (item === undefined) {
            /** If the program enters here, for some reason item is undefined. For debugging see data.service #findBulkPrices() and log the variables. */
            this.calculatingBasePrice = false;

            return toastr.error('De verhuisprijs kan niet worden berekend op dit moment.');
          }

          /** If item is defined set the base price to the item price and update the total prices */
          if (item) {
            this.quotation.base_price = item.price;
            this.updateTotal();
          }

          this.calculatingBasePrice = false;
          return;
        });
    }
  }

  public updateStorageWeeks(): void {
    if (this.quotation.storage_week_text !== null || this.quotation.storage_week_text !== '') {
      this.calculateStorageWeekPrice(this.quotation.estimated_weeks_in_storage, this.quotation.storage_week_text);
    }

    if (this.quotation.storage_value_price !== null) {
      this.updateTotalStorageValue(this.quotation.storage_value_price);
    }
  }

  public updateStorageWeekPrice(): void {
    if (this.quotation.estimated_weeks_in_storage !== null) {
      this.calculateStorageWeekPrice(this.quotation.estimated_weeks_in_storage, this.quotation.storage_week_text);
    }
  }

  public async updateTotal(): Promise<void> {
    this.quotation.subtotal_price = 0;
    this.quotation.subtotal_price += this.quotation.base_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.unpacking_fragile_price || 0;
    this.quotation.subtotal_price += this.quotation.packing_price || 0;
    this.quotation.subtotal_price += this.quotation.coordinator_price || 0;
    this.quotation.subtotal_price += this.quotation.assembly_price || 0;
    this.quotation.subtotal_price += this.quotation.mount_unmount_ict || 0;
    this.quotation.subtotal_price += this.quotation.cable_management || 0;
    this.quotation.subtotal_price += this.quotation.specials_price || 0;
    this.quotation.subtotal_price += this.quotation.moving_truck_15_cubic || 0;
    this.quotation.subtotal_price += this.quotation.moving_truck_40_cubic || 0;
    this.quotation.subtotal_price += this.quotation.mover || 0;
    this.quotation.subtotal_price += this.quotation.remove_lights_curtains_price || 0;
    this.quotation.subtotal_price += this.quotation.piano_grand_organ_price || 0;
    this.quotation.subtotal_price += this.quotation.add_protection || 0;
    this.quotation.subtotal_price += this.quotation.elevator_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.piano_safe_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.floor_surcharge || 0;
    this.quotation.subtotal_price += this.quotation.handyman_certificate_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_week_total_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_handling_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_insurance_total_price || 0;
    this.quotation.subtotal_price += this.quotation.storage_value_total_price || 0;
    this.quotation.subtotal_price += this.quotation.parking_waiver_price || 0;

    if (this.project.type === 'private') {
      this.quotation.subtotal_price += this.quotation.guarantee_certificate_price || 0;
    }

    this.quotation.subtotal_price += +this.quotation.custom_option_1_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_2_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_3_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_4_price || 0;
    this.quotation.subtotal_price += +this.quotation.custom_option_5_price || 0;

    // Calculate vat (exclude guarantee certificate, handyman certificate, parking waiver, storage value and storage insurance)
    const calcVat = (amount) => {
      return amount ? Math.round((amount * 100) * 0.21) / 100 : 0;
    };

    this.quotation.vat_price = 0;
    this.quotation.vat_price += calcVat(this.quotation.base_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.unpacking_fragile_price);
    this.quotation.vat_price += calcVat(this.quotation.packing_price);
    this.quotation.vat_price += calcVat(this.quotation.coordinator_price);
    this.quotation.vat_price += calcVat(this.quotation.assembly_price);
    this.quotation.vat_price += calcVat(this.quotation.mount_unmount_ict);
    this.quotation.vat_price += calcVat(this.quotation.cable_management);
    this.quotation.vat_price += calcVat(this.quotation.specials_price);
    this.quotation.vat_price += calcVat(this.quotation.moving_truck_15_cubic);
    this.quotation.vat_price += calcVat(this.quotation.moving_truck_40_cubic);
    this.quotation.vat_price += calcVat(this.quotation.mover);
    this.quotation.vat_price += calcVat(this.quotation.remove_lights_curtains_price);
    this.quotation.vat_price += calcVat(this.quotation.piano_grand_organ_price);
    this.quotation.vat_price += calcVat(this.quotation.add_protection);
    this.quotation.vat_price += calcVat(this.quotation.elevator_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.piano_safe_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.floor_surcharge);
    this.quotation.vat_price += calcVat(this.quotation.storage_week_total_price);
    this.quotation.vat_price += calcVat(this.quotation.storage_handling_price);

    this.quotation.vat_price += calcVat(+this.quotation.custom_option_1_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_2_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_3_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_4_price);
    this.quotation.vat_price += calcVat(+this.quotation.custom_option_5_price);

    this.quotation.total_price = this.quotation.subtotal_price + this.quotation.vat_price;
  }

  public async submitQuotation(status: string): Promise<void> {
    switch (status) {
      case 'pending':
        this.quotationSent = true;
        this.quotation.status = 'queued';
        break;
      case 'booked':
        this.quotationSent = true;
        this.quotation.status = 'quotation_sent';
        break;
      default:
        break;
    }

    await this.saveQuotation();

    // Update project status
    this.project.status = status;
    await this.projectService.saveProject();

    toastr.info('Offerte wordt verstuurd..', 'Synchronisatie');

    // Trigger sync to backend
    const result = await this.syncService.syncToBackend().catch(() => {
      toastr.error('Offerte kan nog niet verstuurd worden, dit wordt later uitgevoerd zodra er weer verbinding is', 'Synchronisatie');
      this.syncService.shouldSync = true;
    });

    if (result) {
      toastr.success('Offerte succesvol verstuurd', 'Synchronisatie');
    }
  }

  public updateTotalStorageValue(amount: any): void {
    const rounded = amount / 1000;
    const basePrice = (rounded * 0.55);
    this.quotation.storage_value_total_price = basePrice;
    this.updateTotal();

    if (this.quotation.estimated_weeks_in_storage !== null && this.quotation.estimated_weeks_in_storage > 0) {
      this.calculateStorageForPeriod(this.quotation.estimated_weeks_in_storage, basePrice);
    }
  }

  private calculateStorageWeekPrice(weeksOfStorage: number, storageWeekPrice: any): void {
    if ((typeof Number(storageWeekPrice) === 'number' && typeof Number(storageWeekPrice) !== 'undefined')
      && typeof Number(weeksOfStorage) === 'number') {
      this.quotation.storage_week_total_price = Number(storageWeekPrice) * Number(weeksOfStorage);
      this.updateTotal();
    } else {
      toastr.error('Alleen cijfers zijn toegestaan bij `Opslag per kalenderweek`.', 'Foute invoer');
    }
  }

  private calculateStorageForPeriod(weeksOfStorage: number, basePrice: number): void {
    if (typeof Number(weeksOfStorage) === 'number') {
      if (this.project.type === 'private') {
        weeksOfStorage -= 4;
      }

      const numberOfPeriods = Math.ceil(weeksOfStorage / 4);
      this.quotation.storage_value_total_price = basePrice * numberOfPeriods;
      this.updateTotal();
    } else {
      toastr.error('Alleen cijfers zijn toegestaan bij `Waarde van opslag`.', 'Foute invoer');
    }
  }

  private async getSpecialtyCosts(): Promise<void> {
    // Calculate specialty price
    const specialties = [
      { key: 'packing_fragile_price', name: 'Inpakken' },
      { key: 'unpacking_fragile_price', name: 'Uitpakken' },
      { key: 'assembly_price', name: 'Demonteren / monteren' },
      { key: 'packing_price', name: 'Handyman' },
      { key: 'coordinator_price', name: 'Voorman coördinator' },
      { key: 'specials_price', name: 'Specials' },
      { key: 'mount_unmount_ict', name: 'ICT loskoppelen / aansluiten' },
      { key: 'cable_management', name: 'Kabelmanagement' },
      { key: 'remove_lights_curtains_price', name: 'Afhalen verlichting / gordijnrails' },
      { key: 'piano_grand_organ_price', name: 'Piano / vleugel / orgel' },
      { key: 'moving_truck_15_cubic', name: 'Verhuiswagen 15m3' },
      { key: 'moving_truck_40_cubic', name: 'Verhuiswagen 40m3' },
      { key: 'mover', name: 'Verhuizer' }
    ];

    for (const specialty of specialties) {
      this.quotation[specialty.key] = await this.project.getSpecialtyTotalPrice(specialty.name);
    }
  }

  private initWorkAssignmentAddresses(): void {
    this.workAssignmentAddresses = [];
    for (const assignment of this.workAssignments) {
      if (assignment.address_work_assignments.length > 0) {
        for (const address of assignment.address_work_assignments) {
          this.workAssignmentAddresses.push(address);
        }
      }
    }
    this.updateDistance();
  }

  private showClientSignatureForm(): void {
    this.clientSignature.showForm();
  }

  private showValuatorSignatureForm(): void {
    this.valuatorSignature.showForm();
  }

  private async saveQuotation(): Promise<void> {
    this.quotation.estimated_distance = this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km * 1000 : null;

    await this.projectService.saveQuotation(this.quotation);
  }

  private async initForm(): Promise<void> {
    await this.getSpecialtyCosts();
    await this.updateDistance();
    await this.updateBasePrice();
    await this.updateAssemblyPrice();
    await this.updatePackingPrice();
    await this.updateTotal();
  }

  private updateAssemblyPrice(): void {
    if (this.quotation.assembly_price) {
      return;
    }

    this.quotation.assembly_price = this.projectService.calculateAssemblyTotal() * 45;
  }

  private updatePackingPrice(): void {
    if (this.quotation.packing_complete_price) {
      return;
    }

    this.quotation.packing_complete_price = this.projectService.calculatePackingTotal() * 45;
  }

  private async updateDistance(): Promise<void> {
    if (!this.pickupAddress || !this.deliverAddress) {
      return;
    }

    const result = await this.api
      .post('/address/distance', {
        from: {
          street: this.pickupAddress.street,
          housenumber: this.pickupAddress.housenumber,
          zipcode: this.pickupAddress.zipcode,
          city: this.pickupAddress.city,
          country: this.pickupAddress.country
        },
        to: {
          street: this.deliverAddress.street,
          housenumber: this.deliverAddress.housenumber,
          zipcode: this.deliverAddress.zipcode,
          city: this.deliverAddress.city,
          country: this.deliverAddress.country
        }
      })
      .toPromise();

    if (result && result.data && result.data > 0) {
      this.quotation.distance = result.data;
    }

    this.quotation.distance_km = this.quotation.distance / 1000;
    this.quotation.estimated_distance_km = this.quotation.estimated_distance > 0 ? this.quotation.estimated_distance / 1000 : null;
  }

  /**
   * Fill the text field for contact in invoice based on facturation addresses
   */
  private fillInvoiceContact(): void {
    const facturationAddresses: Address[] = [];
    for (const address of this.addresses) {
      if (address.type === 'Facturatieadres' || address.type === 'Facturatie adres') {
        facturationAddresses.push(address);
      }
    }

    /** Take the first value of all facturation addresses */
    this.quotation.invoice_name = facturationAddresses.length > 0 ? facturationAddresses[0].email : '';
  }

  /** Asynchroniously fetch the address belonging to the activity */
  private async retrieveActivityAddresses(): Promise<void> {
    for (const activity of this.project.activities) {
      if (activity.address_id) {
        const address: Address = await this.dataService.getById('addresses', activity.address_id);

        activity.address = address ? address.getDisplayName() : null;
      }
    }
  }
}
