import { DomainModel } from "@domain/domain.model";
import * as uuid from 'uuid/v4';

export class Picture extends DomainModel {
    // Configuration
    public entity = 'picture';
    public table = 'pictures';
    public schema = 'id, remote_id, project_id';
    public sync = true;
    public id: string;

    // Fields
    public project_id: string;
    public name: string;
    public description?: string;
    public data: string;
    public extension: string;
    public timestamp: string;

    // Constructor
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }
    }

    public getData(): any {
        return {
            id: this.id,
            project_id: this.project_id,
            name: this.name,
            description: this.description,
            data: this.data,
            extension: this.extension,
            timestamp: this.timestamp
        };
    }
}