import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectService } from '@shared/services/project.service';
import { SelectItem } from 'primeng/primeng';
import { Inventory } from '@domain/models/inventory.model';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventory-add',
  templateUrl: 'inventory-add.component.html'
})
export class InventoryAddComponent implements OnInit, OnDestroy {
  public form;
  public showErrors = false;
  public project = new Project({});
  public defaultInventories: SelectItem[] = [];
  public isNew: boolean = true;

  private inventory = new Inventory({});
  private subscriptionProjectLoaded: Subscription;
  private subscriptionDefaultInventoriesLoaded: Subscription;
  private subscriptionRoute: Subscription;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private projectService: ProjectService,
              private route: ActivatedRoute) {
    this.subscriptionRoute = this.route.params.subscribe(async (params: Params) => {
      if (params && params['id']) {
        this.inventory.id = params['id'];
        this.isNew = false;
      }
    });
  }

  public ngOnInit(): void {
    this.initForm();
    this.project = this.projectService.getProject();

    this.projectService.getDefaultInventories();

    this.subscriptionDefaultInventoriesLoaded = this.projectService.defaultInventoriesLoaded.subscribe((inventories) => {
      this.defaultInventories = inventories;
      this.initForm();
    });

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionDefaultInventoriesLoaded) {
      this.subscriptionDefaultInventoriesLoaded.unsubscribe();
    }

    if (this.subscriptionRoute) {
      this.subscriptionRoute.unsubscribe();
    }
  }

  public async initForm(): Promise<void> {
    const existingInventory = await Inventory.query.get(this.inventory.id);
    if (existingInventory) {
      this.inventory = existingInventory;
    }

    let defaultInventoryId: number = this.defaultInventories[0] ? this.defaultInventories[0].value : '';
    if (this.inventory && this.inventory.default_inventory_id) {
      const selectableId = this.defaultInventories.find(
          (inventory: SelectItem) => inventory.value === this.inventory.default_inventory_id
      );
      if (selectableId) {
        defaultInventoryId = selectableId.value;
      }
    }

    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.inventory.id),
      name: this.formBuilder.control(this.inventory && this.inventory.name ? this.inventory.name : '', Validators.required),
      building: this.formBuilder.control(this.inventory && this.inventory.building ? this.inventory.building : '' , Validators.required),
      floor: this.formBuilder.control(this.inventory && this.inventory.floor ? this.inventory.floor : '', Validators.required),
      default_inventory_id: this.formBuilder.control(defaultInventoryId)
    });
  }

  public onSubmit() {
    if (this.form.valid) {
      this.inventory = new Inventory({ ...this.form.value });
      this.inventory.project_id = this.project.id;
      this.projectService.saveNewInventory(this.inventory);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick() {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory');
  }
}
