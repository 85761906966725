import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';

@Component({
  selector: 'app-inventory-work-assignment-item',
  templateUrl: 'work-assignment-item.component.html',
  styleUrls: ['./work-assignment-item.component.scss']
})
export class InventoryWorkAssignmentItemComponent implements OnInit {
  @Input('item') item: WorkAssignmentItem;
  @Input('placeholderName') placeholderName;
  @Output('onDeleteItemClick') onDeleteItemClick = new EventEmitter;

  public times = [];

  constructor() {
    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }
  }

  public ngOnInit() {}

  public onDeleteClick() {
    this.onDeleteItemClick.emit(this.item);
  }
}
