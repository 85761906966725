import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Picture } from '@domain/models/picture.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import * as moment from 'moment';

@Component({
  selector: 'app-picture-item-detail',
  templateUrl: './picture-item-detail.component.html',
  styleUrls: ['./picture-item-detail.component.scss']
})
export class InventoryPictureItemDetailComponent implements OnInit, OnDestroy {
  public isEdit: boolean;
  public form: FormGroup;

  private project: Project;
  private pictureId: string;
  private currentPicture: Picture;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private ng2ImgMaxService: Ng2ImgMaxService,
  ) {
    this.isEdit = false;
  }

  public ngOnInit(): void {
    this.createForm();
    this.project = this.projectService.getProject();

    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (!!params && !!params['id'] && params['id'] !== 'add') {
          this.isEdit = true;
          this.pictureId = params['id'];
          this.setCurrentPicture();
        } else {
          this.isEdit = false;
          this.setDefaultImageNameAndTimeStamp();
        }
      });

    this.projectService.projectLoaded
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        if (!!project) {
          this.project = project;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/pictures');
  }

  /**
   * Submit the form
   * 
   * @returns Promise<void>
   */
  public async submit(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    let picture;

    if (this.isEdit) {
      picture = {
        ...this.currentPicture,
        ...this.form.value
      };
    } else {
      picture = new Picture({
        ...this.form.value,
        project_id: this.project.id
      });
    }

    await this.projectService.savePicture(picture);
    this.projectService.setProjectUpdated();
    this.projectService.saveProject();
    this.onCloseClick();
  }

  /**
   * Upload Image
   * 
   * @param fileInput: any
   * @returns void
   */
  public uploadImage(fileInput: any): void {
    if (!fileInput.target.files || fileInput.target.files.length === 0) {
      return;
    }

    const file = fileInput.target.files[0];
    const extension = file.name.match(/\.[0-9a-z]+$/i);

    if (!file.type.match(/^image\//)) {
      return;
    }

    this.ng2ImgMaxService.resize([file], 500, 500)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          this.form.get('data').patchValue(reader.result);
          this.form.get('extension').patchValue(extension[0]);

          if (this.isEdit) {
            // Always re-set timestamp when uploading a new picture to existing one
            this.setDefaultImageNameAndTimeStamp();
          }
        }, false);

        reader.readAsDataURL(result);
      });
  }

  /**
   * Delete the image from current form
   * 
   * @returns void
   */
  public deleteImage(): void {
    this.form.get('data').patchValue(null);
    this.form.get('extension').patchValue(null);
  }

  /**
   * Sets the current picture
   * 
   * @returns Promise<void>
   */
  private async setCurrentPicture(): Promise<void> {
    this.currentPicture = await Picture.query.get(this.pictureId);
    this.form.patchValue(this.currentPicture);
  }

  /**
   * Set default file name
   * 
   * @returns void
   */
  private setDefaultImageNameAndTimeStamp(): void {
    const toSnakeCase = (value) =>
      value && value
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .join('_');

    const date = moment(new Date())
    const name = `${date.format(`YYYY-MM-DD-HH-mm-ss`)}-${toSnakeCase(this.project.reference_nr)}`;
    const timestamp = date.format(`YYYY-MM-DD HH:mm:ss`);

    this.form.get('name').patchValue(name);
    this.form.get('timestamp').patchValue(timestamp);
  }

  /**
   * Create the form
   * 
   * @returns void
   */
  private createForm(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      description: [null],
      data: [null, Validators.required],
      extension: [null, Validators.required],
      timestamp: [null, Validators.required]
    });
  }
}
