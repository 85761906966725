import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';

@Injectable()
export class ContactListGuard implements CanActivate {

  public constructor( private projectService: ProjectService ) {}

  /**
   * Check if project-client has any contact persons
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  async canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ) {

    if (!await this.projectService.hasContacts(route.parent.params.project)) {
      toastr.error('Het project moet minimaal 1 contactpersoon hebben.');
      return false;
    }

    return true;
  }
}
