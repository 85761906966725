import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@blueprint/services/api.service';
import * as toastr from 'toastr';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Contact } from '@domain/models/contact.model';
import { FormBuilder, Validators } from '@angular/forms';
import { customEmailValidator } from '@shared/services/custom-validators.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-inventory-contacts-detail',
  templateUrl: 'contact-detail.component.html'
})
export class InventoryContactsDetailComponent implements OnInit, OnDestroy {
  public form;
  public showErrors = false;
  public errors: any = {};
  public disabled = false;
  public project = new Project({});
  public ownContacts: any[];
  public globalContactFilters: any[];
  public allContacts: any[];
  public environment: object;

  private contact = new Contact({});
  private routeContactId;

  public mode = {
    isAdd: true,
    alertText: 'Contact succesvol toegevoegd',
  };

  private subscriptionContactsChanged: Subscription;

  public constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private projectService: ProjectService) {
    this.environment = environment;
    this.makeOwnContacts();
  }

  public ngOnInit(): any {
    this.project = this.projectService.getProject();
    this.disabled = this.project.status === 'booked';
    this.initForm();

    this.route.params.subscribe(params => {
      this.routeContactId = params['id'];

      if (this.routeContactId) {
        this.mode.isAdd = false;
        this.projectService.getContact(this.routeContactId);
        this.subscriptionContactsChanged = this.projectService.contactsChanged.subscribe(contact => {
          this.contact = contact;
          this.updateForm();
        });
      }

      this.projectService.projectLoaded.subscribe((project) => {
        this.project = project;
        this.disabled = this.project.status === 'booked';
        this.updateForm();
      });
    });
  }

  public ngOnDestroy() {
    if (this.subscriptionContactsChanged) {
      this.subscriptionContactsChanged.unsubscribe();
    }
  }

  public initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control({ value: this.contact.id, disabled: this.disabled }),
      client_id: this.formBuilder.control({ value: this.project.client.id || null, disabled: this.disabled }),
      globalFilter: this.formBuilder.control({ value: this.globalContactFilters, disabled: this.disabled }),
      ownContacts: this.formBuilder.control({ value: this.ownContacts, disabled: this.disabled }),
      name: this.formBuilder.control({ value: this.contact.name || '', disabled: this.disabled }, Validators.required),
      email: this.formBuilder.control({ value: this.contact.email || '', disabled: this.disabled }, [customEmailValidator]),
      phone: this.formBuilder.control({ value: this.contact.phone || '', disabled: this.disabled }, Validators.required),
      mobile: this.formBuilder.control({ value: this.contact.mobile || '', disabled: this.disabled }),
      remarks: this.formBuilder.control({ value: this.contact.remarks, disabled: this.disabled }),
    });
  }

  public onSubmit(form) {
    if (this.form.valid) {
      this.contact = this.form.value;
      this.projectService.saveContact(this.contact);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  onCloseClick() {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact');
  }

  public selectOwnContact(event: any): void {
    const selected = this.ownContacts.find((contact) => contact.value === event.value);
    this.updateFormToOwnContact(selected);
  }

  /**
   * Filter additional contacts on global filter
   * 
   * @param event: any
   * @returns void
   */
  public filterGlobalContacts(event: any): void {
    if (event.value === null) {
      this.ownContacts = this.allContacts.slice();

      return;
    }

    this.ownContacts = this.allContacts.slice().filter((contact) => contact.globalId === event.value);
  }

  private makeOwnContacts(): void {
    this.ownContacts = [];
    this.globalContactFilters = [];

    if (environment.clientName === 'pavanrooyen-mbo') {
      const additionalContacts = [
        { name: 'Kristel van de Wel', email: 'k.vander.wel@mboutrecht.nl', phone: '06-25705093', value: 2, label: 'Kristel van de Wel' },
        { name: 'Petra van Delsen', email: 'p.delsen@mboutrecht.nl', phone: '06-52112833', value: 3, label: 'Petra Delsen' },
        { name: 'Roeland Plomp', email: 'r.plomp@mboutrecht.nl', phone: '06-46605521', value: 5, label: 'Roeland Plomp' },
        { name: 'Jan lekkerkerker', email: 'j.lekkerkerker@mboutrecht.nl', phone: '06-46605521', value: 6, label: 'Jan lekkerkerker' },
      ];

      this.ownContacts = [ ...this.ownContacts, ...additionalContacts ];
    } else if (environment.clientName === 'pavanrooyen') {
      this.globalContactFilters = [
        { label: 'Geen', value: null },
        { label: 'Liberty Global', value: 1 },
        { label: 'Rabobank Amstel & Vecht', value: 2 },
        { label: 'Givaudan', value: 3 }
      ];

      const additionalContacts = [
        // Liberty
        { name: 'Laura Dumas', email: 'facilities@libertyglobal.com', phone: '020-7783300', globalId: 1, value: 1, label: 'Laura Dumas' },
        { name: 'Ilse Bomhof', email: 'facilities@libertyglobal.com', phone: '020-7783300', globalId: 1, value: 2, label: 'Ilse Bomhof' },
        { name: 'Danny Bruijn', email: 'facilities@libertyglobal.com', phone: '', mobile: '06-28960374', globalId: 1, value: 3, label: 'Danny Bruijn' },
        // Rabobank
        { name: 'Rene van Lunteren', email: 'rene.van.lunteren@rabobank.nl', mobile: '06-12064690', globalId: 2, value: 4, label: 'Rene van Lunteren' },
        { name: 'Rob Lubbers', email: 'rob.lubbers@rabobank.nl', mobile: '06-55735335', globalId: 2, value: 5, label: 'Rob Lubbers' },
        // Givaudan
        { name: 'Arthur Burgerhout', email: 'arthur.burgerhout@givaudan.com', phone: '035-6999111', mobile: '06-46230239', globalId: 3, value: 6, label: 'Arthur Burgerhout' },
        { name: 'Marijke Oudhof', email: 'netherlands.reception@givaudan.com', phone: '035-6992110', mobile: '06-28875210', globalId: 3, value: 7, label: 'Marijke Oudhof' }
      ];

      this.ownContacts = [ ...this.ownContacts, ...additionalContacts ];
    }

    // Keep a copy of all contacts in case we want to filter
    this.allContacts = this.ownContacts.slice();
  }

  private updateFormToOwnContact(selected: any): void {
    this.form.patchValue(selected);
  }

  /**
   * Update the form information
   */
  private updateForm() {
    this.form.reset({
      id: { value: this.contact.id, disabled: this.disabled },
      client_id: { value: this.project.client.id || null, disabled: this.disabled },
      ownContacts: { value: this.ownContacts, disabled: this.disabled },
      name: { value: this.contact.name || '', disabled: this.disabled },
      email: { value: this.contact.email || '', disabled: this.disabled },
      phone: { value: this.contact.phone || '', disabled: this.disabled },
      mobile: { value: this.contact.mobile || '', disabled: this.disabled },
      remarks: { value: this.contact.remarks, disabled: this.disabled },
    });
  }
}
