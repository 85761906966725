import { Injectable } from '@angular/core';
import { Signature } from '@domain/models/signature.model';
import { fromPromise } from '@node_modules/rxjs/observable/fromPromise';
import { Observable } from '@node_modules/rxjs';

@Injectable()
export class SignatureService {
  public addSignatures(signatures: Signature[]): Observable<any> {
    return fromPromise(Signature.query.bulkPut(signatures));
  }

  public removeSignature(id: string): Observable<any> {
    return fromPromise(Signature.query.delete(id));
  }
}
